import Button from "./Button";
import BlockRender from "./BlockRender";
import { withRouter } from "react-router";
import {
  FocusOnce,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import CircleLoader from "./CircleLoader";
import { Datepicker } from "baseui/datepicker";
function BannerHeading({
  path,
  roles = [],
  title,
  history,
  text,
  onClick,
  btn,
  downloadInvoices,
  isLoading,
  eport,
  setExport,
  date,
  setDate,
}) {
  //   const [eport, setExport] = useState(false);
  const onChangeDate = (value) => {
    setDate(Array.isArray(value) ? value : [value]);

    if (value.length === 1 || (value.length === 0 && date.length === 0)) return;
  };
  console.log("date", date);
  return (
    <div className="banner-heading">
      <h2>{title}</h2>
      {text && (
        <BlockRender roles={roles}>
          <div>
            <Button
              style={{ marginRight: "10px" }}
              // size="compact"
              onClick={() => {
                if (path) history.push(path);
                else onClick?.();
              }}
            >
              {text}
            </Button>

            {btn && <Button onClick={() => setExport(true)}>Export</Button>}
          </div>
        </BlockRender>
      )}

      <Modal
        isOpen={eport}
        unstable_ModalBackdropScroll={true}
        onClose={() => setExport(false)}
      >
        {isLoading && <CircleLoader />}
        <FocusOnce>
          <ModalHeader>Export Invoices</ModalHeader>
        </FocusOnce>
        <ModalBody>
          <Datepicker
            range
            clearable
            value={date}
            size="compact"
            maxDate={new Date()}
            formatString="dd.MM.yyyy"
            placeholder="DD.MM.YYYY - DD.MM.YYYY"
            onChange={({ date }) => onChangeDate(date)}
            quickSelect
            mask={null}
            // overrides={{
            //   CalendarContainer: {
            //     style: ({ $theme }) => ({
            //       width: `100%`,
            //     })
            //   }
            // }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            type="button"
            kind="tertiary"
            onClick={() => setExport(false)}
          >
            No
          </ModalButton>
          <ModalButton onClick={downloadInvoices}>Yes</ModalButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default withRouter(BannerHeading);
