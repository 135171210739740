import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../../redux/auth/authAction";
import BlockRender from "../../_common/BlockRender";
import Button from "../../_common/Button";
import ToasterService from "../../_services/toasterService";
import Session from "../../_services/session.service";
import Logo from "./../../_assets/logo-long.png";

const Header = (props) => {
  const dispatch = useDispatch();
  const { history, location } = props;
  const { pathname } = location;

  useEffect(() => {
    let sesion;

    if (pathname !== "/") sesion = new Session();

    return () => {
      if (pathname !== "/") sesion.destroy();
    };
  }, [pathname]);

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
    ToasterService.showSuccessToaster("Logged out successfully");
  };

  return (
    <div className="main-header">
      {pathname === "/" ? null : (
        <Grid align={ALIGNMENT.center}>
          <Cell span={[4, 8, 12]}>
            <div className="header">
              <img src={Logo} alt="logo" className="brand-logo" />
              <div className="links">
                <Link
                  to="/invoices"
                  className={`header-link ${
                    (pathname === "/invoices" || pathname === "/dashboard") &&
                    "active"
                  }`}
                >
                  Invoices
                </Link>
               
                <BlockRender roles={["accountant"]}>
                <Link
                  to="/clients"
                  className={`header-link ${
                    pathname === "/clients" && "active"
                  }`}
                >
                  Clients
                </Link>
                  <Link
                    to="/issues"
                    className={`header-link ${
                      pathname === "/issues" && "active"
                    }`}
                  >
                    Issues
                  </Link>
                </BlockRender>
                <BlockRender roles={["driver", "accountant", "manager"]}>
                  <>
                    <Link
                      to="/users"
                      className={`header-link ${
                        pathname === "/users" && "active"
                      }`}
                    >
                      Users
                    </Link>
                  </>
                </BlockRender>
                <BlockRender roles={["driver", "accountant"]}>
                  <>
                    <Link
                      to="/drivers"
                      className={`header-link ${
                        pathname === "/drivers" && "active"
                      }`}
                    >
                      Drivers
                    </Link>

                    <Link
                      to="/partners"
                      className={`header-link ${
                        pathname === "/partners" && "active"
                      }`}
                    >
                      Partners
                    </Link>

                    <Link
                      to="/company"
                      className={`header-link ${
                        pathname === "/company" && "active"
                      }`}
                    >
                      Companies
                    </Link>
                    <Link
                      to="/earnings"
                      className={`header-link ${
                        pathname === "/earnings" && "active"
                      }`}
                    >
                      Earnings
                    </Link>
                    <Link
                      to="/private-earnings"
                      className={`header-link ${
                        pathname === "/private-earnings" && "active"
                      }`}
                    >
                      Private Earnings
                    </Link>

                    {/* <Link
                      to="/careers"
                      className={`header-link ${
                        pathname === "/careers" && "active"
                      }`}
                    >
                      Careers
                    </Link> */}
                    <Link
                      to="/vehicle"
                      className={`header-link ${
                        pathname === "/vehicle" && "active"
                      }`}
                    >
                      Vehicle
                    </Link>
                  </>
                </BlockRender>
                <Link
                  to="/expense"
                  className={`header-link ${
                    pathname === "/expense" && "active"
                  }`}
                >
                  Expense
                </Link>

                <BlockRender roles={["driver", "accountant", "manager"]}>
                  <>
                    <Link
                      to="/cash-paid"
                      className={`header-link ${
                        pathname === "/cash-paid" && "active"
                      }`}
                    >
                      Cash Paid
                    </Link>
                  </>
                </BlockRender>
              </div>
              <Button size="compact" onClick={() => handleLogout()}>
                Logout
              </Button>
            </div>
          </Cell>
        </Grid>
      )}
    </div>
  );
};

export default withRouter(Header);
