import { Field, reduxForm } from "redux-form";
import { Cell, Grid } from "baseui/layout-grid";
import Button from "../../../../../_common/Button";
import { required } from "../../../../../_common/validate";
import InputRedux from "../../../../../_common/InputRedux";
import { FormControl } from "baseui/form-control";
import SelectCompany from "../../../../auth/Register/SelectCompany";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../../../../../_common/Input";
import { useSelector } from "react-redux";
import { getCompanies } from "../../../../../redux/company/companyAction";
import MultiFileUpload from "../../../../../_common/MultiFileUpload";

const AddVehicleForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const [companyList, setCompanyList] = useState([]);

  const companies = useSelector((state) => state.company.companies);

  useEffect(() => {
    if (companies?.[0] === null) return;

    let arr = [];

    companies.forEach(({ _id, name }) => arr.push({ id: _id, label: name }));

    setCompanyList(arr);
  }, [companies]);
  useEffect(() => dispatch(getCompanies()), [dispatch]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid gridMargins={0} gridGutters={8}>
        <Cell span={[4, 8, 4]}>
          <SelectCompany />
        </Cell>
        <Cell span={[4, 8, 4]}>
          <Field
            name="plate"
            label="Plate Number"
            validate={[required]}
            component={InputRedux}
          />
        </Cell>

        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Konzessionsurkunde"}
            overrides={{
              Label: { style: { marginBottom: "4px" } },
              ControlContainer: { style: { position: "relative" } },
            }}
          >
            <Field
              type="date"
              component={Input}
              label="Konzessionsurkunde"
              placeholder="dd-mm-yyyy"
              name="concession_deed"
            />
          </FormControl>
        </Cell>

        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Kfz-Haftpflichtversicherung"}
            overrides={{
              Label: { style: { marginBottom: "4px" } },
              ControlContainer: { style: { position: "relative" } },
            }}
          >
            <Field
              type="date"
              component={Input}
              label="Kfz-Haftpflichtversicherung"
              placeholder="dd-mm-yyyy"
              name="liability_insurance"
            />
          </FormControl>
        </Cell>

        {/* <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Fahrzeugschein"}
            overrides={{
              Label: { style: { marginBottom: "4px" } },
              ControlContainer: { style: { position: "relative" } },
            }}>
            <Field
              type="date"
              component={Input}
              label="Fahrzeugschein"
              placeholder="dd-mm-yyyy"
              name="vehicle_registration"
            />
          </FormControl>
        </Cell> */}
      </Grid>
      <Grid gridMargins={0} gridGutters={8}>
        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Vehicle Documents"}
            overrides={{ Label: { style: { marginBottom: "4px" } } }}
          >
            <>
              <MultiFileUpload
                name="documents"
                form="AddVehicleForm"
                accept={[".png", ".jpg", ".jpeg", ".pdf"]}
              />
              <Field
                type="hidden"
                component="input"
                name="documents"
                autoComplete="false"
              />
            </>
          </FormControl>
        </Cell>
      </Grid>

      <Grid gridMargins={0} gridGutters={8}>
        <Cell skip={[0, 0, 0]} span={[4, 4, 4]}>
          <Button style={{ width: "100%" }}>Submit</Button>
        </Cell>
      </Grid>
    </form>
  );
};

export default reduxForm({ form: "AddVehicleForm" })(AddVehicleForm);
