import axios from "axios";
import Session from "./session.service";
import authService from "./auth.service";
import ToasterService from "./toasterService";
import { config } from "../config";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

axios.defaults.baseURL = config.API_URL;

axios.interceptors.request.use(
  function (config) {
    return {
      ...config,
      cancelToken: source.token,
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    console.log("http response", res.config.url, res);
    Session.setLatestServerCall();
    return res;
  },
  (err) => {
    console.log("http err", err, err.message);

    if (err.message === "Network Error") {
      ToasterService.showErrorToaster(err.message);
      throw err;
    }

    Session.setLatestServerCall();
    const { code, message } = err.response.data;

    ToasterService.showErrorToaster(message);

    if (code === 401) {
      authService.logout();
      source.cancel(message);

      setTimeout(() => {
        source = CancelToken.source();
        if (window.location.pathname !== "/") window.location.assign("/");
      }, 300);
    }

    throw err;
  }
);

export function setJWT() {
  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("token");
}

export function setMultiPart() {
  axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
}

const http = {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
};

export default http;
