import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { addCompany } from "../../../../../redux/company/companyAction";
import {
  addVehicle,
  updateVehicle,
} from "../../../../../redux/vehicle/vehicleAction";
import BannerHeading from "../../../../../_common/BannerHeading";
import CircleLoader from "../../../../../_common/CircleLoader";
import UpdateVehicleForm from "../updateVehicle/UpdateVehicleForm";
import AddVehicleForm from "./AddVehicleForm";

function AddVehicle(props) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSubmit = (values) => {

    setLoading(true);

    let data = { ...values };
    data.company_id = data.company[0].id;
    delete data.company;


    if (id) {
      data._id = id;
      dispatch(updateVehicle(data))
        .then(() => {
          props.history.push("/vehicle");
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch(addVehicle(data))
        .then(() => {
          // setSuccessful(true);
          setLoading(false);
          props.history.push("/vehicle");
        })
        .catch(() => {
          // setSuccessful(false);
          setLoading(false);
        });
    }
  };

  return (
    <div className="dashboard-main">
      <Grid align={ALIGNMENT.center}>
        <Cell span={[4, 8, 12]}>
          <BannerHeading title={`${id ? "Update" : "Add"}  Vehicle`} />
          <div className="form-box">
            {loading && <CircleLoader />}
            <AddVehicleForm onSubmit={handleSubmit} />
            {id && <UpdateVehicleForm />}
          </div>
        </Cell>
      </Grid>
    </div>
  );
}

export default withRouter(AddVehicle);
