import authService from "./auth.service";
import http, { setJWT } from "./http";
let index;

const vehicleService = {
  getIndex: () => index,
  setIndex: (id) => (index = id),

  getVehicles: (data) => {
    setJWT();
    return http.post("/admin/getvehicles", data);
  },

  addVehicles: (data) => {
    return http.post("/admin/addvehicle", data);
  },
  deleteVehicle: (data) => {
    setJWT();
    return http.post("/admin/deletevehicle", data);
  },

  updateVehicle: (data) => {
    return http.post("/admin/updatevehicle", data);
  },

  // filterVehicle: (data) => {
  //   setJWT();
  //   return http.post(authService.apiURL() + "/getSearchedDrivers", data);
  // },
};

export default vehicleService;
