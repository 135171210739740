import http from "./http";
import ToasterService from "./toasterService";

let hash = {};
let is_waiting = false;
let waiting_list = [];
let MapService = {
	set: function (key, value) {
		hash[key.replace(/\s+/g, "")] = value;
	},
	get: function (key) {
		return hash[key];
	},
	getWholeHash: function () {
		return hash;
	},
	isWaiting: function () {
		return is_waiting;
	},
	calculate: async function (pickup, destination) {
		let pick = pickup.replace(/\s+/g, "");
		let des = destination.replace(/\s+/g, "");
		let pickup_id = this.get(pick);
		let destination_id = this.get(des);
		if (!pickup_id || !destination_id) return true;
		is_waiting = true;
		waiting_list.push(1);
		let response = await this.calculateDistance(pickup_id, destination_id);
		waiting_list.pop();
		if (response) {
			if (waiting_list.length === 0) {
				is_waiting = false;
			}
			let key = pick + des;
			this.set(key, response);
			return true;
		}
		return false;
	},
	exists: function (key) {
		if (hash[key]) {
			return true;
		}
		return false;
	},
	clear: function () {
		hash = {};
	},
	calculateDistance: async function (originPlaceId, destinationPlaceId) {
	

		// if(!originPlaceId || !destinationPlaceId) return false;
		return new Promise((resolve, reject) => {
			let directionsService = new window.google.maps.DirectionsService();
			directionsService.route(
				{
					origin: { placeId: originPlaceId },
					destination: { placeId: destinationPlaceId },
					travelMode: window.google.maps.TravelMode.DRIVING,
				},
				(response, status) => {
					if (status === "OK") {
						let distance = response.routes[0].legs[0].distance.value / 1000;

						resolve(distance);
					} else {
						ToasterService.showErrorToaster("Failed To calculate Distance");
						resolve(false);
					}
				}
			);
		});
	},
	wait: async () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve("");
			}, 500);
		});
	},
	getPlaceAutoComplete: (data) => {
		return http.post("maps/suggestions", data);
	},
};

export default MapService;
