import {
  StyledRoot,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableHeadRow,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableBodyCell,
} from "baseui/table-semantic";
import { useSelector } from "react-redux";
import TableLoader from "../../../_common/TableLoader";


const expense = [
    {
    name: "Zahid",
    expense: "2000",
    fuel:"200",
    wash:"500",
    water:"500",
    parking:"500",
    other:"500"

},
]

const MonthlyExpense = () => {

  const { expenses } = useSelector((state) => state.expense.totalExpense);
  

  console.log("expenses", expenses);



  return (
    <div className="form-box">
      <h1>Total Expense</h1>
      <StyledRoot>
        {expenses?.length===0 ? (
          <div>No results found</div>
        ) : (
          <StyledTable>
            <StyledTableHead>
              <StyledTableHeadRow>
                {/* <StyledTableHeadCell>#</StyledTableHeadCell> */}
                <StyledTableHeadCell>Driver Name</StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Fuel
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Wash
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Water
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Parking
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Others
                </StyledTableHeadCell>

                <StyledTableHeadCell>Total Expense</StyledTableHeadCell>
              </StyledTableHeadRow>
            </StyledTableHead>
            <StyledTableBody>
              {expenses && expenses.length !== 0
                && expenses?.map((data) => {
                    return (
                      <StyledTableBodyRow>
                        {/* <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                        }}>
                        {index +1}
                      </StyledTableBodyCell> */}
                        <StyledTableBodyCell
                          style={{
                            verticalAlign: "middle",
                          }}>
                          {data.driver}
                        </StyledTableBodyCell>

                        <StyledTableBodyCell
                        // style={{
                        //   verticalAlign: "middle",
                        //   }}
                        >
                          € {data.exps?.[0]?.price?.toFixed(2)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                        // style={{
                        //   verticalAlign: "middle",
                        //   }}
                        >
                          € {data.exps?.[1]?.price?.toFixed(2)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                        // style={{
                        //   verticalAlign: "middle",
                        //   }}
                        >
                          € {data.exps?.[2].price?.toFixed(2)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                        // style={{
                        //   verticalAlign: "middle",
                        //   }}
                        >
                          € {data.exps?.[3]?.price?.toFixed(2)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                        // style={{
                        //   verticalAlign: "middle",
                        //   }}
                        >
                          € {data.exps?.[4]?.price?.toFixed(2)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell
                        // style={{
                        //   verticalAlign: "middle",
                        //   }}
                        >
                          € {data?.total?.toFixed(2)}
                        </StyledTableBodyCell>
                      </StyledTableBodyRow>
                    );
                  })
               
                  }
            </StyledTableBody>
          </StyledTable>
        )}
      </StyledRoot>
    </div>
  );
};

export default MonthlyExpense;
