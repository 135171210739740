import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import {
  addExpense,
  updateExpense,
} from "../../../../../redux/expense/expenseAction";
import BannerHeading from "../../../../../_common/BannerHeading";
import CircleLoader from "../../../../../_common/CircleLoader";
import UpdateExpenseForm from "../updateExpense/UpdateExpenseForm";
import AddExpenseForm from "./addExpenseForm";

function AddExpense(props) {
  const [loading, setLoading] = useState(false);
  const driver = useSelector((state) => state.auth?.user);

  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSubmit = (values) => {
    setLoading(true);

    let data = { ...values };


    data.name = data.fueltype[0].id;

    if (data.driver) {
      data.driverId = data.driver[0].id;
    } else {
      data.driverId = driver._id;
    }

    delete data.driver;
    data.price = Number(data.price);

    if (data.name === "other") {
      data.name = data.other;
      delete data.other;
    }

    delete data.fueltype;

    if (id) {
      data._id = id;
      dispatch(updateExpense(data))
        .then(() => {
          setLoading(false);
          props.history.push("/expense");
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      dispatch(addExpense(data))
        .then(() => {
          // setSuccessful(true);
          setLoading(false);
          props.history.push("/expense");
        })
        .catch(() => {
          // setSuccessful(false);
          setLoading(false);
        });
    }
  };

  return (
    <div className="dashboard-main">
      <Grid align={ALIGNMENT.center}>
        <Cell span={[4, 8, 12]}>
          <BannerHeading title={`Add Expense`} />
          <div className="form-box">
            {loading && <CircleLoader />}
            <AddExpenseForm isLoading={false} onSubmit={handleSubmit} />
            {id && <UpdateExpenseForm />}
          </div>
        </Cell>
      </Grid>
    </div>
  );
}

export default withRouter(AddExpense);
