import expenseService from "../../_services/expense.service";
import ToasterService from "../../_services/toasterService";
import { SET_MESSAGE } from "../message/messageTypes";
import {
  ADD_EXPENSE,
  CLEAR_EXPENSE,
  DELETE_EXPENSE,
  GET_EXPENSE,
  INCREMENT_PAGE,
  TOTAL_EXPENSE,
  UPDATE_EXPENSE,
} from "./expenseType";

export const addExpense = (data) => (dispatch) => {
  return expenseService.addExpense(data).then(
    (response) => {
      dispatch({
        type: ADD_EXPENSE,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Expense added successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getExpense = (data) => (dispatch) => {
  return expenseService.getExpense(data).then(
    (response) => {

      dispatch({
        type: GET_EXPENSE,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      // ToasterService.showSuccessToaster(
      //   `${response.data.data.expenses.length} results found`
      // );

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EXPENSE,
        payload: error.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateExpense = (data) => (dispatch) => {
  return expenseService.updateExpense(data).then(
    (response) => {
      dispatch({
        type: UPDATE_EXPENSE,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Expense updated successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const deleteExpense = (data) => (dispatch) => {
  return expenseService.deleteExpense({ _id: data }).then(
    (response) => {
      dispatch({
        type: DELETE_EXPENSE,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Expense deleted successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const clearExpense = () => (dispatch) => {
  dispatch({
    type: CLEAR_EXPENSE,
  });
};

export const incrementPage = () => (dispatch) => {
  dispatch({
    type: INCREMENT_PAGE,
  });
};

export const getTotalExpense = (data) => (dispatch) => {
  return expenseService.totalExpense(data).then(
    (response) => {
      dispatch({
        type: TOTAL_EXPENSE,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: TOTAL_EXPENSE,
        payload: error.data
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};