import * as React from "react";
import { value, Pagination } from "baseui/pagination";

const CustomPagination = ({totalItems=0,itemsPerPage=20,onChange,currentPage}) => {

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <Pagination
      numPages={totalPages}
      currentPage={currentPage}
      onPageChange={({ nextPage }) => {
        let page=Math.min(Math.max(nextPage, 1), totalPages)
        onChange(page)
      }}
      
    />
  );
};

export default CustomPagination;
