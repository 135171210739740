import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./auth/authReducer";
import driverReducer from "./driver/driverReducer";
import invoiceReducer from "./invoice/invoiceReducer";
import messageReducer from "./message/messageReducer";
import { LOGOUT } from "./auth/authTypes";
import earningsReducer from "./earnings/earningsReducer";
import partnerReducer from "./partner/partnerReducer";
import companyReducer from "./company/companyReducer";
import issueReducer from "./issue/issueReducer";
import careerReducer from "./career/careerReducer";
import userReducer from "./user/userReducer";
import vehicleReducer from "./vehicle/vehicleReducer";
import expenseReducer from "./expense/expenseReducer";
import cashPaidReducer from "./cashPaid/cashPaidReducer";
import clientReducer from "./client/clientReducer";

const appReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  message: messageReducer,
  invoice: invoiceReducer,
  drivers: driverReducer,
  earnings: earningsReducer,
  partner: partnerReducer,
  company: companyReducer,
  careers: careerReducer,
  issue: issueReducer,
  user: userReducer,
  vehicle:vehicleReducer,
  expense:expenseReducer,
  cashPaid:cashPaidReducer,
  client:clientReducer

});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
